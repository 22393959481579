<template>
  <div ref="swiper" class="swiper">
    <div class="swiper-wrapper">
      <!-- Слайдам обязательно добавлять класс swiper-slide -->
      <slot />
    </div>
	<div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { Swiper, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

let swiper = {};

export default {
	name: 'CardSlider',

	data() {
		return {
			swiperOptions: {
				slidesPerView: 1,
				spaceBetween: 20,
				modules: [Pagination],
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
				}
			}
		}
	},

	mounted() {
	this.initSwiper();
	},

	beforeDestroy() {
	swiper.destroy();
	},

	methods: {
		initSwiper() {
			const swiperElem = this.$refs.swiper;
			const options = { ...this.swiperOptions };

			if (!Object.prototype.hasOwnProperty.call(options, 'on')) {
				options.on = {};
			}

			options.on.init = (swiper) => {
				this.$emit('init', swiper);
			};

			swiper = new Swiper(swiperElem, options);
		},
	},
};
</script>

<style>
	.swiper-wrapper {
		margin-bottom: 45px;
	}

	.swiper-slide {
		cursor: grab;
	}
</style>
